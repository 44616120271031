.loader-wrapper {
  clear: both;
  @include clearfix;
  height: 0;
  position: relative;
}

.loader {
  @include bubble;
  @include left-bubble;
  @include pop;
  background-color: $question-bg;
  padding-top: 1.25rem;
  position: absolute;
  height: 2.5rem;
  visibility: hidden;

  &-show {
    visibility: visible;
  }

  &-hidden {
    display: none;
  }

  .dot {
    float: left;
    margin-right: 2px;
    line-height: 1.5rem;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    background: $question-colour;
    border-radius: .125rem;
    animation: loader .5s infinite alternate;
    animation-timing-function: cubic-bezier(.25, .60, .35, 1);

    &:nth-of-type(2) {
      animation-delay: .2s;
    }
    &:nth-of-type(3) {
      animation-delay: .3s;
    }
  }
}

@keyframes loader {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: .25;
    transform: translate3d(0, -.5rem, 0);
  }
}
