@mixin bubble {
  clear: both;
  font-size: $exchange-font-size;
  line-height: $exchange-line-height;
  margin-bottom: $exchange-spacing;
  max-width: $exchange-max-width;
  padding: $exchange-padding-v $exchange-padding-h;

  @media only screen and (max-width: 320px) {
    max-width: $exchange-max-width-xxs;
  }
}

@mixin left-bubble {
  border-radius: .125rem 1.25rem 1.25rem 1.25rem;
  float: left;
}

@mixin right-bubble {
  border-radius: 1.25rem 1.25rem .125rem 1.25rem;
  float: right;
}

@mixin coin($size: .75rem) {
  background-color: $sunglow;
  box-shadow: 1px 1px 0 $coral;
  border-radius: 50%;
  content: '';
  display: inline-block;
  margin-right: .25rem;
  height: $size;
  width: $size;
}

@mixin pop {
  animation: pop .5s cubic-bezier(0.5, -0.3, 0.7, 0.05);

  @keyframes pop {
    0% {
      opacity: 0;
      transform: scale(.97);
    }
    60% {
      transform: scale(.97);
    }
    80% {
      transform: scale(1.01);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@mixin bop {
  animation: bop .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);

  @keyframes bop {
    0% {
    }
    60% {
      transform: scale(1.01);
    }
    80% {
      transform: scale(1);
    }
    100% {
    }
  }
}

@mixin slide {
  animation: slide .3s ease-out;

  @keyframes slide {
    0% {
      opacity: 0;
      transform: translate3d(0, 1rem, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

@mixin drop {
  animation: drop .3s;
  animation-timing-function: cubic-bezier(.2, .75, .5, 1);

  @keyframes drop {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    50% {
      opacity: 1;
      transform: translateY(.25rem);
    }
    100% {
      transform: none;
    }
  }
}

@mixin drop-through {
  animation: dropthrough .4s;
  // animation-timing-function: cubic-bezier(.2, .75, .5, 1);
  // animation-timing-function: cubic-bezier(0.280, 0.150, 0.170, 0.915);
  // animation-timing-function: ease-in-out;
  animation-timing-function: ease-out;
  animation-delay: .2s;

  @keyframes dropthrough {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    50% {
      opacity: 1;
      transform: translateY(.25rem);
    }
    100% {
      opacity: 0;
      transform: none;
    }
  }
}

// @mixin drop-through {
//   animation: dropthrough .5s;
//   animation-timing-function: cubic-bezier(.2, .75, .5, 1);
//   animation-timing-function: cubic-bezier(1, 0, 0, 1);
//   // animation-timing-function: ease-in-out;
//   animation-delay: .1s;
//
//   @keyframes dropthrough {
//     0% {
//       opacity: 0;
//     }
//     50% {
//       opacity: 1;
//     }
//     100% {
//       transform: scale(4);
//       opacity: 0;
//     }
//   }
// }

@mixin increment {
  animation: increment .2s;

  @keyframes increment {
    0% {
      opacity: .5;
      transform: scale(.9)
    }
    50% {
      transform: scale(1.1)
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
}
