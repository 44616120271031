$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);

$blue:    $denim;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     $french-raspberry;
$orange:  $coral;
$yellow:  $sunglow;
$green:   $sea-green;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $sunset-orange,
  secondary: $outer-space,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $white,
  dark: $yankees-blue
);

$body-bg:       $white !default;
$body-color:    $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color:            $blue;
$link-hover-color:      darken($link-color, 15%);

$grid-breakpoints: (
  xs: 0,
  sm: 572px,
  md: 768px,
  lg: 872px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 840px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-gutter-width: 32px;

$font-family-sans-serif: "aktiv-grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.25rem;
$h6-font-size: .875rem;

$headings-font-weight:   700;

$navbar-light-color:                $outer-space;
$navbar-light-hover-color:          darken($outer-space, 10%);
$navbar-light-active-color:         darken($outer-space, 20%);
$navbar-light-disabled-color:       rgba($outer-space,.5);


//
// $custom-control-gutter:   1.5rem;
// $custom-control-spacer-y: .25rem;
// $custom-control-spacer-x: 1rem;
//
$custom-control-input-size:       1.25rem;
$custom-control-input-bg:         $columbia-blue;
// $custom-control-input-bg-size:    50% 50%;
// $custom-control-input-box-shadow: inset 0 .25rem .25rem rgba($black,.1);
//
// $custom-control-input-disabled-bg:       $gray-200;
// $custom-control-label-disabled-color:  $gray-600;
//
// $custom-control-input-checked-color:      $white;
// $custom-control-input-checked-bg:         theme-color("primary");
// $custom-control-input-checked-box-shadow: none;
//
$custom-control-input-focus-box-shadow: 0 0 0 3px rgba($sunset-orange, .5);
//
// $custom-control-input-active-color:      $white;
$custom-control-input-active-bg:         lighten(theme-color("primary"), 10%);
// $custom-control-input-active-box-shadow: none;
//
$custom-checkbox-indicator-border-radius: .125rem;
// $custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-input-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
//
// $custom-checkbox-indicator-indeterminate-bg: theme-color("primary");
// $custom-checkbox-indicator-indeterminate-color: $custom-control-input-checked-color;
// $custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
// $custom-checkbox-indicator-indeterminate-box-shadow: none;
