$badge-color: $sea-green;

.marketing {
  background-color: $glitter;
  margin-top: 0;
  margin-bottom: 0;

  .navbar {
    .navigation-logo {
      height: 4rem;
    }
  }
}

.hero {
  .hero-badge,
  .hero-title,
  .hero-subtitle,
  .hero-cta {
    margin-bottom: 2rem
  }

  .hero-badge {
    margin-top: 4rem;

    .badge {
      background-color: transparent;
      border: 1px solid $badge-color;
      border-radius: 2rem;
      color: $badge-color;
      font-size: .75rem;
      font-weight: 500;
      line-height: 1rem;
      padding: .25rem 1rem;
      text-transform: uppercase;
    }
  }

  .hero-title {
    color: $yankees-blue;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.0;
  }

  .hero-cta {
    .btn {
      @include button-variant($blue, $blue);
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5rem;
      padding: 1rem;
    }
  }

  .hero-subtitle {
    color: $outer-space;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .hero-signin {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

.device {
  background-color: $yankees-blue;
  padding: 3rem .5rem;
  border-radius: 1rem;
  position: relative;

  &:before {
    content: '';
    background-color: $paynes-grey;
    border-radius: 1rem;
    display: block;
    height: .75rem;
    width: .75rem;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .device-image {
    display: block;
    width: 100%;
  }
}

.section-heading {
  color: $sunset-orange;
  margin-bottom: 2rem;
  text-align: center;
}

.how {
  background-color: $yankees-blue;
  color: $white;
  padding: 2rem 0;

  .how-row {
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-bottom: 4rem;
      margin-top: 4rem;
      text-align: left;
    }

    .how-img {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  h3 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .how-img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.questions {
  background-color: $white;
  padding: 2rem;
}
