$question-bg:                  $glitter;
$question-colour:              $yankees-blue;
$answer-bg:                    $white;
$answer-colour:                $outer-space;
$answer-border:                $columbia-blue;
$response-bg:                  $sunset-orange;
$response-colour:              $white;
$response-border:              $sunset-orange;
$footer-bg:                    $outer-space;

$navbar-border: .25rem;
$navbar-logo-height: 2rem;
$navbar-height: $navbar-logo-height + 1rem + 1rem + $navbar-border;

$exchange-font-size: 1rem;
$exchange-line-height: 1.5rem;
$exchange-spacing: 1rem;
$exchange-padding-h: 1rem;
$exchange-padding-v: .5rem;
$exchange-max-width: 75%;
$exchange-max-width-xxs: 85%;

$answer-padding-h: 1rem;
$answer-padding-v: .6875rem;
$answer-spacing: .5rem;
