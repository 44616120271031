.navbar {
  &-light {
    border-bottom: $navbar-border solid $columbia-blue;
  }

  .navbar-brand {
    margin-right: 0;
    padding: .75rem 0 .25rem;

    .navigation-logo {
      display: block;
      height: $navbar-logo-height;
    }
  }

  .nav-link {
    font-weight: 500;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .navbar-nav .dropdown-menu {
    float: right;
    position: absolute;
    left: auto;
    right: 0;
  }

  .nav-item {
    @include media-breakpoint-up(md) {
      margin-left: .5rem;
    }

    &.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      background-color: $glitter;
      border-radius: $border-radius;
    }
  }
}

.navigation .navigation-invite {
  display: none;

  @include media-breakpoint-up(md) {
    display: inherit;
  }

  .nav-link {
    color: $link-color;

    &:focus,
    &:hover {
      color: $link-hover-color;
    }
  }
}

.navigation-name {
  display: none;
  margin-right: .5rem;

  @include media-breakpoint-up(md) {
    display: inline;
  }
}

.navigation .dropdown-menu {
  .dropdown-invite,
  .dropdown-name {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.navigation-coins-value {
  position: relative;

  // .coins {
  //   background-color: $sunglow;
  //   box-shadow: inset -1px -1px 0 $coral;
  //   border-radius: 50%;
  //   display: inline-block;
  //   width: .75rem;
  //   height: .75rem;
  //   position: absolute;
  //   right: .5rem;
  //   top: .5rem;
  //   opacity: 0;
  // }

  .coin {
    @include coin();
    margin-right: 0;
  }

  &.count {
    .coin {
      @include drop();
    }
  }

  .stack {
    border: 1px solid $columbia-blue;
    border-radius: 100% / 6px;
    display: inline-block;
    height: 1rem;
    width: .75rem;
    position: relative;
    transition: opacity .2s;

    @mixin stacked-coin($position: 0) {
      background-color: $sunglow;
      box-shadow: 0 1px 0 $coral;
      border-radius: 50%;
      display: block;
      height: .375rem;
      width: .75rem;
      position: absolute;
      bottom: $position;
      left: -1px;
    }

    .coin25 {
      @include stacked-coin(0);
    }
    .coin50 {
      @include stacked-coin(.1875rem);
    }
    .coin75 {
      @include stacked-coin(.375rem);
    }
    .coin100 {
      @include stacked-coin(.5625rem);
    }
  }

  &.count {
    .stack {
      opacity: 0;
    }
    .coins {
      @include drop-through;
    }

    .navigation-coins-number {
      @include increment;
    }
  }
}
