@import "colours";
@import "global-variables";
@import "mixins";

@import "node_modules/bootstrap/scss/functions.scss";
@import "bootstrap-variables";

@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/font-awesome/scss/font-awesome.scss";

@import "components/loader";
@import "components/navigation";

@import "pages/account";
@import "pages/marketing";

body {
  margin-top: $navbar-height + 1rem;
  margin-bottom: 9rem;
}

.question-group {
  &-hidden {
    display: none;
  }

  &-auto {
    .question {
      margin-bottom: .25rem;
    }
  }
}

.question-wrapper {
  position: sticky;
  top: $navbar-height;
  z-index: 1;
  @include clearfix;
}

.question {
  @include pop;
  @include bubble;
  @include left-bubble;
  background-color: $question-bg;
  color: $question-colour;

  &-hidden {
    display: none;
  }
}

.response {
  @include bubble;
  @include right-bubble;
  @include slide;
  background-color: $response-bg;
  color: $response-colour;
  opacity: 1;
  transition: opacity .3s;

  &-hidden {
    display: none;
  }

  &-sent {
    @include bop;
    opacity: 1;
  }
}

.answers {
  @include slide;
  clear: both;

  &-fixed {
    background-color: $footer-bg;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem .75rem;
    overflow-y: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  &-hidden {
    display: none;
  }
}

.answer {
  background-color: $answer-bg;
  border: 1px solid $answer-border;
  border-radius: $border-radius;
  color: $answer-colour;
  display: inline-block;
  line-height: $exchange-line-height;
  margin-bottom: $answer-spacing;
  margin-right: $answer-spacing;
  padding: $answer-padding-v $answer-padding-h;
}

.multi-select-option {
  padding-left: .5rem + 1rem + .5rem;

  .custom-control-label::before,
  .custom-control-label::after {
    left: -1.25rem;
    top: .125rem;
  }
}

.extended-select-option {
  border-style: none;
  display: block;
  margin-bottom: 0;
  margin-left: .25rem;
  margin-right: 0;
  padding-left: .5rem + 1.25rem;
  padding-top: .5rem;
  padding-bottom: .5rem;

  .custom-control-label::before,
  .custom-control-label::after {
    left: -1.25rem;
    top: .125rem;
  }
}

.input-footer {
  background-color: $footer-bg;
  position: fixed;
  padding: 1rem 0 3.5rem;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 4rem);
  transition-property: transform, opacity, visibility;
  transition-duration: .3s;
  z-index: 2000;

  .container {
    display: flex;
  }

  .input-footer-value {
    background-color: $glitter;
    border-style: none;
    height: auto;
    max-height: 6rem;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media only screen and (max-width: 320px) {
      max-height: 4rem;
    }
  }

  .input-footer-value,
  .input-footer-btn {
    border-width: 0;
    line-height: 1.5rem;
    padding: .75rem;
  }

  .input-footer-btn {
    margin-left: .75rem;
    min-width: 3rem;
  }

  &-visible {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }
}


.dropdown-menu {
  border-style: none;
  box-shadow: 0 .125rem .5rem 0 rgba($yankees-blue, 0.1);
}

.btn-outline-secondary {
  &:focus {
    border-color: rgba($outer-space, .5);
  }
}

.credits {
  white-space: nowrap;

  &::before {
    @include coin;
  }
}

.page-header {
  padding-bottom: 1rem;

  .page-header-heading {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0;
    margin-top: .5rem;
  }
}

.btn-blue {
  @include button-variant($blue, $blue);
}

.back-link {
  display: block;
  font-weight: 500;

  .fa {
    float: left;
    line-height: inherit;
    margin-right: .25rem;
  }
}

.page-main {
  background-color: $white;
  border-radius: .25rem;
  padding: 2rem 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.emoji-scale {
  display: flex;
  width: 100%;

  .btn {
    border-color: $answer-border;
    flex-grow: 1;
    font-size: 1.75rem;
  }
}

.modal.fade .modal-dialog {
  transform: translate(0, 25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop {
  background-color: $yankees-blue;
}

.modal-content {
  border-style: none;
  border-radius: 1.5rem 1.5rem .125rem 1.5rem;

  .modal-header,
  .modal-footer {
    border-style: none;
  }

  .modal-header {
    padding-top: 1.5rem;
  }

  .modal-title {
    font-weight: 500;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
