.account {
  background-color: $white-smoke;
  margin-bottom: 2rem;
}

.wallet {
  .wallet-credits {
    margin-bottom: 2rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .credits {
      display: block;
      font-size: 4rem;
      font-weight: 300;
      line-height: 4rem;
      margin-bottom: .5rem;

      &::before {
        @include coin(3rem);
        box-shadow: 2px 2px 0 $coral;
      }
    }

    .credits-label {
      color: $paynes-grey;
    }
  }
}

.redeem {
  .redeem-item {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    width: 100%;
  }

  .redeem-icon {
    margin-right: 1rem;
  }

  .redeem-button {
    font-weight: 500;

    &.disabled {
      color: $paynes-grey;
    }
  }

  .redeem-credits,
  .redeem-value {
    flex-grow: 1;
  }

  .redeem-value {
    text-align: right;
  }

  .redeem-action {
    display: block;
    margin-top: 1rem;
    width: 100%;

    .btn {
      display: block;
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    .redeem-value {
      text-align: initial;
    }

    .redeem-action {
      margin-top: 0;
      width: auto;

      .btn {
        display: inline-block;
        width: auto;
      }
    }
  }

  .redeem-message {
    margin-bottom: 0;
  }
}
