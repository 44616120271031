// Bright
$sunset-orange:                #FB6B53;
$coral:                        #FE8B4B;
$denim:                        #0D5CE5;
$sunglow:                      #FFC82D;
$sea-green:                    #16B497;
$french-raspberry:             #D3304B;

// Neutral
$yankees-blue:                 #1C2338;
$outer-space:                  #3E495C;
$paynes-grey:                  #6E778A;
$wild-blue-yonder:             #A3B2CC;
$columbia-blue:                #D1D9E8;
$glitter:                      #EBEFF8;
$white-smoke:                  #F2F5FA;
$white:                        #fff;

// Legacy
$black-olive:                  #3B3B3B;
$black-shadows:                #B8B5B1;
$platinum:                     #E2E0DF;
$isabelline:                   #F5F1EC;
